<template>
  <div name="index">
    <div class="container" style="margin: 0 10px">
      <div class="source">
        {{ $t('public.currentLocation') }}：&nbsp;&nbsp;
        <router-link :to="{ path: '/' }">{{ $t('index.index') }}</router-link>
        &nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;{{ $t('index.complaintReply') }}
      </div>

      <div v-for="item in resultList" :key="item.id">
        <div class="news">
          <div class="personInfo">
            <div class="news-bg">{{ $t('index.complaint.detailInfo') }}</div>
            <div class="info-title">
              <h3><span>{{ $t('index.complaint.letterTitle') }}：</span>{{ item.title }}</h3>
              <h3><span>{{ $t('index.complaint.letterType') }}：</span>{{ item.type }}</h3>
              <h3><span>{{ $t('index.complaint.handleState') }}：</span>{{ item.state }}</h3>
              <h3><span>{{ $t('index.complaint.letterTime') }}：</span>{{ item.createTime }}</h3>
            </div>
          </div>
        </div>

        <div class="news">
          <div class="personInfo">
            <div class="news-bg">{{ $t('index.complaint.replyMessage') }}</div>
            <div class="info-title">
              <h3><span>{{ $t('index.complaint.replyUnit') }}：</span>{{ depname }}</h3>
              <h3><span>{{ $t('index.complaint.replyContent') }}：</span>{{ item.backcontent }}</h3>
              <h3><span>{{ $t('index.complaint.replyTime') }}：</span>{{ item.updateTime }}</h3>
            </div>
          </div>
        </div>

        <div class="news"
          v-if="item.state === '完结' && item.satisfaction !== null"
        >
          <div class="personInfo">
            <div class="news-bg">{{ $t('index.complaint.evaluateResult') }}</div>
            <div class="info-title">
              <h3><span>{{ $t('index.complaint.evaluateResult') }}：</span>{{ item.satisfaction }}</h3>
            </div>
          </div>
        </div>
      </div>

      <div class="news">
        <div class="personInfo">
          <div class="news-bg">{{ $t('index.complaint.processingLog') }}</div>
          <ul class="logInfo">
            <li v-for="item in logList" :key="item.id">
              {{ item.time }}&nbsp;&nbsp;{{ item.content }}
            </li>
          </ul>
        </div>
      </div>

      <div class="footer">
        <p>{{ $t('footer.producer') }}：{{ $t('footer.producerName') }}</p>
        <!-- <p>{{ $t('footer.contactAddress') }}：{{ $t('footer.contactAddressName') }}</p> -->
      </div>
    </div>
  </div>
</template>
<script>
import CryptoJS from "../utils/CryptoJS";
import BASE from "../utils/base";

export default {
  name: "app",
  components: {},
  activated() {
    // when the Vue app is booted up, this is run automatically.
    // let id = this.$route.params.id;
    let id = this.$route.query.id;
    let data = "DpHxKkMbPymRYCFi" + new Date().getTime();
    let _data = CryptoJS.encrypt(data);
    console.log(_data);
    let that = this;
    this.axios
      .get(BASE.genUrl("zxSuperviseLetter/list", { id: id }))
      .then((res) => {
        console.log("投诉查询", res);
        let data = res.data;
        if (data.result) {
          that.resultList = data.result.records;

          console.log(that.resultList, 11111);

          // 获取部门名称
          if (that.resultList.length > 0) {
            let _id = that.resultList[0].departId;
            this.axios
              .get(BASE.genUrl("zxSuperviseComm/getDepartName", { id: _id }))
              .then((res) => {
                console.log("获取部门名称", res);
                let data = res.data;
                if (data.result && data.result.length > 0) {
                  that.depname = data.result[0].name;
                }
              });
          }
        }
      });
    // 处理日志
    this.axios
      .get(BASE.genUrl("zxSuperviseLetterjournal/list", { letterId: id }))
      .then((res) => {
        console.log("处理日志", res);
        let data = res.data;
        if (data.result) {
          that.logList = data.result.records;
        }
      });

    // 获取浏览量
    // this.axios
    //   .get(BASE.genUrl('zxCommViews/list', {type:'%E6%99%BA%E6%85%A7%E7%9B%91%E7%9D%A3'}))
    //   .then(res => {
    //     console.log('浏览量', res)
    //     let data = res.data;
    //     if(data.result) {
    //       that.todayView = data.result.today;
    //       that.totalView = data.result.total;
    //     }
    // });
  },
  computed: {
    prev: function () {
      return this.pageNo - 1 <= 0 ? 1 : this.pageNo - 1;
    },
    next: function () {
      return this.result.length >= this.pageSize
        ? this.pageNo + 1
        : this.pageNo;
    },
  },
  data: () => ({
    result: [],
    active: 1,
    todayView: null,
    totalView: null,
    resultList: [],
    logList: [],
    depname: "",
  }),
  methods: {},
};
</script>
<style scoped>
.source {
  text-align: left;
  color: #fff;
  padding: 0.1rem;
  font-size: 14px;
  margin-bottom: 0.3rem;
}

.source a {
  color: #fff;
}

.logInfo {
  margin-top: 2rem;
  font-size: 14px;
}

.personInfo {
  background: #fff;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  text-align: left;
  border-radius: 0.5rem;
}

.footer {
  color: #fe9b4a;
  text-align: center;
  font-size: 12px;
}

ol,
ul,
li {
  list-style: disc;
  /* padding: 0;
  margin: 0; */
}

li {
  padding: 0.5rem 0;
  margin-left: 1rem;
}

.banner {
  width: 100%;
  height: auto;
}

.page {
  background: #b51414;
}

.detail {
  margin-top: 2.5rem;
  border-radius: 0.5rem;
  background: #fff;
  text-align: left;
}

.personInfo span {
  color: #b51414;
}

.news-bg {
  background: url("../assets/tab-bg.png") center top no-repeat;
  background-size: 60%;
  position: absolute;
  width: 100%;
  top: -5px;
  font-weight: bold;
  color: #fff;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0;
}

.news {
  background: #fff;
  padding-top: 0.5rem;
  position: relative;
  border-radius: 0.5rem;
  margin-top: 1.5rem;
}

.container .news .list {
  text-align: left;
  padding: 0.8rem;
}

.info-title {
  margin-top: 2rem;
  padding: 0 0.5rem;
}

h3 {
  margin-bottom: 0.3rem;
}

.detail p {
  font-size: 14px;
  line-height: 25px;
}

.detail li {
  margin: 0 0.5rem;
  border-bottom: 1px solid #ccc;
}

.info-satisfaction {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
</style>
